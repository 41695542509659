<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Species</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="species-form">
          <v-text-field
            label="Name *"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-text-field
            label="Slug *"
            v-model="slug"
            type="text"
            outlined
            dense
            background-color="white"
            :error="errors.hasOwnProperty('slug')"
            :error-messages="errors['slug']"
          ></v-text-field>
          <v-file-input
            v-model="fields.seed_illustration"
            accept="image/png"
            placeholder="Choose a Seed Illustration Image"
            prepend-icon="mdi-camera"
            label="Seed Illustration *"
            outlined
            background-color="white"
            @change="seedIllustrationHasChanged()"
            :error="errors.hasOwnProperty('seed_illustration')"
            :error-messages="errors['seed_illustration']"
          ></v-file-input>
          <v-select
            label="Category *"
            v-model="fields.species_category_id"
            :items="categories"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('species_category_id')"
            :error-messages="errors['species_category_id']"
          ></v-select>
          <v-text-field
            label="Scientific Name *"
            v-model="fields.scientific_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('scientific_name')"
            :error-messages="errors['scientific_name']"
          ></v-text-field>
          <v-textarea
            label="Description *"
            v-model="fields.description"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          ></v-textarea>
          <v-textarea
            label="Additional Information"
            v-model="fields.additional_information"
            rows="3"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('additional_information')"
            :error-messages="errors['additional_information']"
          ></v-textarea>
          <v-textarea
            label="Works Well With"
            v-model="fields.works_well_with"
            rows="3"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('works_well_with')"
            :error-messages="errors['works_well_with']"
          ></v-textarea>
          <v-textarea
            label="Uses"
            v-model="fields.uses"
            rows="3"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('uses')"
            :error-messages="errors['uses']"
          ></v-textarea>
          <v-textarea
            label="Mixture Sowing Rate Advice"
            v-model="fields.mixture_sowing_rate_advice"
            rows="3"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('mixture_sowing_rate_advice')"
            :error-messages="errors['mixture_sowing_rate_advice']"
          ></v-textarea>
          <v-textarea
            label="Frost Tolerance"
            v-model="fields.frost_tolerance"
            rows="3"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('frost_tolerance')"
            :error-messages="errors['frost_tolerance']"
          ></v-textarea>
          <v-textarea
            label="Ideal Sowing Time"
            v-model="fields.ideal_sowing_time"
            rows="3"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('ideal_sowing_time')"
            :error-messages="errors['ideal_sowing_time']"
          ></v-textarea>
          <v-textarea
            label="Persistence"
            v-model="fields.persistence"
            rows="3"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('persistence')"
            :error-messages="errors['persistence']"
          ></v-textarea>
          <v-textarea
            label="Sowing Rate Advice"
            v-model="fields.sowing_rate_advice"
            rows="3"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('sowing_rate_advice')"
            :error-messages="errors['sowing_rate_advice']"
          ></v-textarea>
          <v-textarea
            label="Strengths"
            v-model="fields.strengths"
            rows="3"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('strengths')"
            :error-messages="errors['strengths']"
          ></v-textarea>
          <v-textarea
            label="Management"
            v-model="fields.management"
            rows="3"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('management')"
            :error-messages="errors['management']"
          ></v-textarea>
          <v-textarea
            label="Yield"
            v-model="fields.yield"
            rows="3"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('yield')"
            :error-messages="errors['yield']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="species-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      fields: {
        name: null,
        slug: null,
        seed_illustration: null,
        seed_illustration_has_changed: false,
        species_category_id: null,
        scientific_name: null,
        description: null,
        additional_information: null,
        works_well_with: null,
        uses: null,
        mixture_sowing_rate_advice: null,
        frost_tolerance: null,
        ideal_sowing_time: null,
        persistence: null,
        sowing_rate_advice: null,
        strengths: null,
        management: null,
        yield: null,
      },
      species: null,
      errors: {},
    };
  },

  computed: {
    slug: {
      get() {
        if (this.isEditing) {
          return this.fields.slug;
        }

        let slug = this.fields.name;

        if (slug === "" || slug === null) {
          return slug;
        }

        slug = slug.trim();
        slug = slug.replaceAll("&", "and");
        slug = slug.replaceAll(",", "");
        slug = slug.replaceAll(/[^a-zA-Z ]/g, "");
        slug = slug.replaceAll(" ", "-");
        slug = slug.toLowerCase();
        if (slug.substr(-1) === "-") {
          slug = slug.substr(0, slug.length - 1);
        }

        return slug;
      },
      set(value) {
        return value;
      },
    },

    categories() {
      return this.$store.state.grogreen.species["categories"];
    },
  },

  watch: {
    slug(value) {
      this.fields.slug = value;
    },
  },

  methods: {
    openForm: function (species = null) {
      if (species !== null) {
        this.isEditing = true;
        this.species = species;
        this.fields.name = species.name;
        this.fields.slug = species.slug;
        this.fields.seed_illustration = species.seed_illustration
          ? new File([species.seed_illustration], species.seed_illustration)
          : null;
        this.fields.species_category_id = species.species_category_id;
        this.fields.scientific_name = species.scientific_name;
        this.fields.description = species.description;
        this.fields.additional_information = species.additional_information;
        this.fields.works_well_with = species.works_well_with;
        this.fields.uses = species.uses;
        this.fields.mixture_sowing_rate_advice =
          species.mixture_sowing_rate_advice;
        this.fields.frost_tolerance = species.frost_tolerance;
        this.fields.ideal_sowing_time = species.ideal_sowing_time;
        this.fields.persistence = species.persistence;
        this.fields.sowing_rate_advice = species.sowing_rate_advice;
        this.fields.strengths = species.strengths;
        this.fields.management = species.management;
        this.fields.yield = species.yield;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.species = null;
      this.fields.name = null;
      this.fields.slug = null;
      this.fields.seed_illustration = null;
      this.fields.seed_illustration_has_changed = false;
      this.fields.species_category_id = null;
      this.fields.scientific_name = null;
      this.fields.description = null;
      this.fields.additional_information = null;
      this.fields.works_well_with = null;
      this.fields.uses = null;
      this.fields.mixture_sowing_rate_advice = null;
      this.fields.frost_tolerance = null;
      this.fields.ideal_sowing_time = null;
      this.fields.persistence = null;
      this.fields.sowing_rate_advice = null;
      this.fields.strengths = null;
      this.fields.management = null;
      this.fields.yield = null;
      this.errors = {};
    },

    saveForm: function () {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.speciesId = this.species.id;
      }

      this.$store
        .dispatch("grogreen/species/saveSpecies", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    seedIllustrationHasChanged: function () {
      this.fields.seed_illustration_has_changed = true;
    },
  },
};
</script>
